/**
 * 前置拦截-导航守卫
 * 
 * @param to    前往路由
 * @param from  来源路由
 * @param next  放行路由
 */
export const authController = async (to, from, next) => {
    // console.log('前置拦截:登录');
    // 判断是否有路由信息
    const token = localStorage.getItem("ACCESS_TOKEN") ? localStorage.getItem("ACCESS_TOKEN") : false;

    document.title = token ? (to.meta.title ? to.meta.title + " - 管理应用" : "管理系统") : "系统登录";
    // console.log(token);
    if (to.path === "/login") {
        token ? next("/") : next();
    } else {
        token ? next() : next("/login");
    }
}