import { h, resolveComponent } from "vue";

// 直接嵌套页面，避免重复嵌套
const RouteView = {
    name: 'RouteView',
    render: () => h(resolveComponent("router-view"))
}
let number = 1;

// 遍历路由
export const createRouteMenu = (menuList) => {
    const userRoutes = menuList.map(menu => {
        const { title, meta, name, show, path, redirect, component, children = [] } = menu;
        number = number + 1;
        const currentMenu = {
            id: number,
            title, name, show, path, redirect,
            component: component == '' ? RouteView : component,
            meta: meta ? meta : { title },
            children: children.length > 0 ? createRouteMenu(children) : [],
        }
        if (children.length == 0) {
            delete currentMenu.children
        }
        return currentMenu
    })
    return userRoutes
}

// 获取所有插件路由信息
export const createRouteByList = (menu_routes, is_root = false) => {
    let auth_routes = createRouteMenu(menu_routes);

    if (is_root == true) {
        return auth_routes;
    }

    // 获取登录授权得到的权限列表
    let auth_list_str = localStorage.getItem('USER_AUTH');
    if (!auth_list_str) return [];

    let auth_array = auth_list_str.split(",");
    return filterRoutes(auth_routes, auth_array);
}

// 去掉没有权限的栏目
export const filterRoutes = (route_all, auth_array) => {
    console.log('filterRoutes');
    console.log(route_all);
    console.log(auth_array);
    const user_auth = route_all.filter(item => {
        if (auth_array.indexOf(item.name) > -1) {
            if (item.children && item.children.length > 0) {
                item.children = filterRoutes(item.children, auth_array);
            }
            return item;
        } else {
            return false;
        }
    })
    console.log(user_auth);
    return user_auth;
}