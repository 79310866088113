import { h, resolveComponent } from "vue";
import { Layout } from '@/layout'


// 直接嵌套页面，避免重复嵌套
const RouteView = {
    name: 'RouteView',
    render: () => h(resolveComponent("router-view"))
}

/// 基础路由
export default [
    {
        path: '/',
        name: 'root',
        meta: { title: '首页' },
        hidden: false,
        component: Layout,
        redirect: '/index',
        children: [
            {
                title: '首页',
                meta: { title: '首页', icon: 'icon-shouye1' },
                path: '/index',
                name: 'index',
                show: true,
                component: () => import('@/views/index/Index.vue'),
            },
            {
                title: '内容',
                meta: { title: '内容', icon: 'icon-neirongliebiao' },
                path: '/document',
                name: 'document',
                show: true,
				component: RouteView,
                redirect: '/document/content',
                children: [
                    {
                        title: '文档管理',
                        meta: { title: '文档管理' },
                        path: '/document/content',
                        name: 'document_content',
                        show: true,
						component: RouteView,
                        redirect: '/document/content/list',
                        children: [
                            {
                                title: '文档列表',
                                meta: { title: '文档列表' },
                                path: '/document/content/list',
                                name: 'document_content_list',
                                show: true,
                                component: () => import('@/views/document/List.vue'),
                            },
                            {
                                title: '发布文档',
                                meta: { title: '发布文档' },
                                path: '/document/content/publish',
                                name: 'document_content_publish',
                                show: false,
                                component: () => import('@/views/document/CreateArticle.vue'),
                            },
                            {
                                title: '编辑文档',
                                meta: { title: '编辑文档' },
                                path: '/document/content/update',
                                name: 'document_content_update',
                                show: false,
                                component: () => import('@/views/document/UpdateArticle.vue'),
                            },
                            {
                                title: '栏目管理',
                                meta: { title: '栏目管理' },
                                path: '/document/content/category',
                                name: 'document_content_category',
                                show: true,
                                component: () => import('@/views/document/Category.vue'),
                            },
                            {
                                title: '草稿箱',
                                meta: { title: '草稿箱' },
                                path: '/document/content/draft',
                                name: 'document_content_draft',
                                show: true,
                                component: () => import('@/views/document/Draft.vue'),
                            },
                            {
                                title: '回收站',
                                meta: { title: '回收站' },
                                path: '/document/content/recycle',
                                name: 'document_content_recycle',
                                show: true,
                                component: () => import('@/views/document/Recycle.vue'),
                            },
                        ]
                    },
                    {
                        title: '广告管理',
                        meta: { title: '广告管理' },
                        path: '/advs/manage',
                        name: 'advs_manage',
                        show: true,
						component: RouteView,
                        redirect: '/advs/manage/list',
                        children: [
                            {
                                title: '广告列表',
                                meta: { title: '广告列表' },
                                path: '/advs/manage/list',
                                name: 'advs_manage_list',
                                show: true,
                                component: () => import('@/views/advs/List.vue'),
                            },
                            {
                                title: '发布广告',
                                meta: { title: '发布广告' },
                                path: '/advs/manage/create',
                                name: 'advs_manage_create',
                                show: false,
                                component: () => import('@/views/advs/Create.vue'),
                            },
                            {
                                title: '编辑广告',
                                meta: { title: '编辑广告' },
                                path: '/advs/manage/edit',
                                name: 'advs_manage_edit',
                                show: false,
                                component: () => import('@/views/advs/Edit.vue'),
                            },
                            {
                                title: '广告分析',
                                meta: { title: '广告分析' },
                                path: '/advs/manage/analyse',
                                name: 'advs_manage_analyse',
                                show: true,
                                component: () => import('@/views/advs/Analyse.vue'),
                            },
                        ]
                    },
                    {
                        title: '其他管理',
                        meta: { title: '其他管理' },
                        path: '/message/list',
                        name: 'message_list',
                        show: true,
                        component: RouteView,
                        redirect: '/message/list',
                        children: [
                            {
                            title: '在线留言',
                            meta: { title: '在线留言' },
                            path: '/message/list',
                            name: 'message_list',
                            show: true,
                            component: () => import('@/views/message/list.vue'),
                            },
                            {
                            title: '查看',
                            meta: { title: '查看' },
                            path: '/message/read',
                            name: 'message_list_read',
                            show: false,
                            component: () => import('@/views/message/read.vue'),
                            }
                        ]
                        },
                ]
            },
            {
                title: '会员',
                meta: { title: '会员', icon: 'icon-huiyuan1' },
                path: '/user',
                name: 'user',
                show: false,
				component: RouteView,
                redirect: '/user/manage/list',
                children: [
                    {
                        title: '会员管理',
                        meta: { title: '会员管理' },
                        path: '/user/manage',
                        name: 'user_manage',
                        show: true,
						component: RouteView,
                        redirect: '/user/manage/list',
                        children: [{
                            title: '会员列表',
                            meta: { title: '会员列表' },
                            path: '/user/manage/list',
                            name: 'user_manage_list',
                            show: true,
                            component: () => import('@/views/user/manage/List.vue'),
                        },
                        {
                            title: '会员等级',
                            meta: { title: '会员等级' },
                            path: '/user/manage/level',
                            name: 'user_manage_level',
                            show: true,
                            component: () => import('@/views/user/manage/Level.vue'),
                        },
                        {
                            title: '会员标签',
                            meta: { title: '会员标签' },
                            path: '/user/manage/label',
                            name: 'user_manage_label',
                            show: true,
                            component: () => import('@/views/user/manage/Label.vue'),
                        },
                        {
                            title: '会员导入',
                            meta: { title: '会员导入' },
                            path: '/user/manage/import',
                            name: 'user_manage_import',
                            show: true,
                            component: () => import('@/views/user/manage/Import.vue'),
                        },
                        {
                            title: '黑名单',
                            meta: { title: '黑名单' },
                            path: '/user/manage/black',
                            name: 'user_manage_black',
                            show: true,
                            component: () => import('@/views/user/manage/Black.vue'),
                        },]
                    },
                    {
                        title: '会员运营',
                        meta: { title: '会员运营' },
                        path: '/user/promotion',
                        name: 'user_promotion',
                        show: true,
						component: RouteView,
                        redirect: '/user/promotion/cluster',
                        children: [
                            {
                                title: '会员群体',
                                meta: { title: '会员群体' },
                                path: '/user/promotion/cluster',
                                name: 'user_promotion_cluster',
                                show: true,
                                component: () => import('@/views/user/promotion/Cluster.vue'),
                            },
                            {
                                title: '会员分析',
                                meta: { title: '会员分析' },
                                path: '/user/promotion/analyse',
                                name: 'user_promotion_analyse',
                                show: true,
                                component: () => import('@/views/user/promotion/Analyse.vue'),
                            },
                        ]
                    },
                    {
                        title: '会员设置',
                        meta: { title: '会员设置' },
                        path: '/user/setting',
                        name: 'user_psetting',
                        show: true,
						component: RouteView,
                        redirect: '/user/setting/login',
                        children: [
                            {
                                title: '登录设置',
                                meta: { title: '登录设置' },
                                path: '/user/setting/login',
                                name: 'user_setting_login',
                                show: true,
                                component: () => import('@/views/user/setting/Login.vue'),
                            },
                            {
                                title: '注册设置',
                                meta: { title: '注册设置' },
                                path: '/user/setting/register',
                                name: 'user_setting_register',
                                show: true,
                                component: () => import('@/views/user/setting/Register.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                title: '商品',
                meta: { title: '商品', icon: 'icon-31quanbushangpin' },
                path: '/goods',
                name: 'goods',
                show: false,
				component: RouteView,
                redirect: '/goods/list',
                children: [
                    {
                        title: '商品列表',
                        meta: { title: '商品列表' },
                        path: '/goods/list',
                        name: 'goods_list',
                        show: true,
                        component: () => import('@/views/goods/goods/List.vue'),
                    },
                    {
                        title: '发布商品',
                        meta: { title: '发布商品' },
                        path: '/goods/addgoods',
                        name: 'goods_addgoods',
                        show: false,
                        component: () => import('@/views/goods/goods/Add.vue'),
                    },
                    {
                        title: '编辑商品',
                        meta: { title: '编辑商品' },
                        path: '/goods/editgoods',
                        name: 'goods_editgoods',
                        show: false,
                        component: () => import('@/views/goods/goods/Edit.vue'),
                    },
                    {
                        title: '商品分类',
                        meta: { title: '商品分类' },
                        path: '/goods/category',
                        name: 'goods_category',
                        show: true,
                        component: () => import('@/views/goods/category/List.vue'),
                    },
                    {
                        title: '添加商品分类',
                        meta: { title: '添加商品分类' },
                        path: '/goods/category/add',
                        name: 'goods_category_add',
                        show: false,
                        component: () => import('@/views/goods/category/Add.vue'),
                    },
                    {
                        title: '编辑商品分类',
                        meta: { title: '添加商品分类' },
                        path: '/goods/category/edit',
                        name: 'goods_category_edit',
                        show: false,
                        component: () => import('@/views/goods/category/Edit.vue'),
                    },
                    {
                        title: '商品品牌',
                        meta: { title: '商品品牌' },
                        path: '/goods/brand',
                        name: 'goods_brand',
                        show: true,
                        component: () => import('@/views/goods/brand/List.vue'),
                    },
                    {
                        title: '添加商品品牌',
                        meta: { title: '添加商品品牌' },
                        path: '/goods/brand/add',
                        name: 'goods_brand_add',
                        show: false,
                        component: () => import('@/views/goods/brand/Add.vue'),
                    },
                    {
                        title: '编辑商品品牌',
                        meta: { title: '编辑商品品牌' },
                        path: '/goods/brand/edit',
                        name: 'goods_brand_edit',
                        show: false,
                        component: () => import('@/views/goods/brand/Edit.vue'),
                    },
                    {
                        title: '商品标签',
                        meta: { title: '商品标签' },
                        path: '/goods/label',
                        name: 'goods_label',
                        show: true,
                        component: () => import('@/views/goods/label/List.vue'),
                    },
                    {
                        title: '添加商品标签',
                        meta: { title: '添加商品标签' },
                        path: '/goods/label/add',
                        name: 'goods_label_add',
                        show: false,
                        component: () => import('@/views/goods/label/Add.vue'),
                    },
                    {
                        title: '编辑商品标签',
                        meta: { title: '编辑商品标签' },
                        path: '/goods/label/edit',
                        name: 'goods_label_edit',
                        show: false,
                        component: () => import('@/views/goods/label/Edit.vue'),
                    },
                    {
                        title: '商品参数',
                        meta: { title: '商品参数' },
                        path: '/goods/attr',
                        name: 'goods_attr',
                        show: true,
                        component: () => import('@/views/goods/attr/List.vue'),
                    },
                    {
                        title: '添加商品参数',
                        meta: { title: '添加商品参数' },
                        path: '/goods/attr/add',
                        name: 'goods_attr_add',
                        show: false,
                        component: () => import('@/views/goods/attr/Add.vue'),
                    },
                    {
                        title: '编辑商品参数',
                        meta: { title: '商品参数' },
                        path: '/goods/attr/edit',
                        name: 'goods_attr_edit',
                        show: false,
                        component: () => import('@/views/goods/attr/Edit.vue'),
                    },
                    {
                        title: '商品服务',
                        meta: { title: '商品服务' },
                        path: '/goods/service',
                        name: 'goods_service',
                        show: true,
                        component: () => import('@/views/goods/service/List.vue'),
                    },
                    {
                        title: '添加商品服务',
                        meta: { title: '添加商品服务' },
                        path: '/goods/service/add',
                        name: 'goods_service_add',
                        show: false,
                        component: () => import('@/views/goods/service/Add.vue'),
                    },
                    {
                        title: '编辑商品服务',
                        meta: { title: '编辑商品服务' },
                        path: '/goods/service/edit',
                        name: 'goods_service_edit',
                        show: false,
                        component: () => import('@/views/goods/service/Edit.vue'),
                    },
                    {
                        title: '回收站',
                        meta: { title: '回收站' },
                        path: '/goods/recycle',
                        name: 'goods_recycle',
                        show: true,
                        component: () => import('@/views/goods/recycle/List.vue'),
                    },
                ]
            },
            {
                title: '订单',
                meta: { title: '订单', icon: 'icon-shouye' },
                path: '/order',
                name: 'order',
                show: false,
				component: RouteView,
                redirect: '/order/manage/list',
                children: [
                    {
                        title: '订单管理',
                        meta: { title: '订单管理' },
                        path: '/order/list',
                        name: 'order_list',
                        show: true,
						component: RouteView,
                        redirect: '/order/manage/list',
                        children: [
                            {
                                title: '全部订单',
                                meta: { title: '全部订单' },
                                path: '/order/manage/list',
                                name: 'order_manage_list',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '待发货',
                                meta: { title: '待发货' },
                                path: '/order/manage/delivery',
                                name: 'order_manage_delivery',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '待收货',
                                meta: { title: '待收货' },
                                path: '/order/manage/receipt',
                                name: 'order_manage_receipt',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '待付款',
                                meta: { title: '待付款' },
                                path: '/order/manage/pay',
                                name: 'order_manage_pay',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '已完成',
                                meta: { title: '已完成' },
                                path: '/order/manage/complete',
                                name: 'order_manage_complete',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '已取消',
                                meta: { title: '已取消' },
                                path: '/order/manage/cancel',
                                name: 'order_manage_cancel',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '订单详情',
                                meta: { title: '订单详情' },
                                path: '/order/manage/detail',
                                name: 'order_manage_detail',
                                show: false,
                                component: () => import('@/views/order/manage/Detail.vue'),
                            },
                        ]
                    },
                    {
                        title: '订单处理',
                        meta: { title: '订单处理' },
                        path: '/order/done',
                        name: 'order_done',
                        show: false,
						component: RouteView,
                        redirect: '/order/manage/refund',
                        children: [
                            {
                                title: '售后管理',
                                meta: { title: '售后管理' },
                                path: '/order/manage/refund',
                                name: 'order_manage_refund',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '发货管理',
                                meta: { title: '发货管理' },
                                path: '/order/manage/tools',
                                name: 'order_manage_tools',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                            {
                                title: '订单导出',
                                meta: { title: '订单导出' },
                                path: '/order/manage/export',
                                name: 'order_manage_export',
                                show: true,
                                component: () => import('@/views/order/manage/List.vue'),
                            },
                        ]
                    },

                ]
            },
            {
                title: '应用',
                meta: { title: '应用', icon: 'icon-yingyong1' },
                path: '/client',
                name: 'client',
                show: true,
				component: RouteView,
                redirect: '/client/app',
                children: [
                    {
                        title: '应用列表',
                        meta: { title: '应用列表' },
                        path: '/client/app',
                        name: 'client_app',
                        show: true,
						component: RouteView,
                        redirect: '/client/app/list',
                        children: [
                            {
                                title: '所有应用',
                                meta: { title: '所有应用' },
                                path: '/client/app/list',
                                name: 'client_app_list',
                                show: true,
                                component: () => import('@/views/client/app/List.vue'),
                            },
                            {
                                title: '创建应用',
                                meta: { title: '创建应用' },
                                path: '/client/app/create',
                                name: 'client_app_create',
                                show: false,
                                component: () => import('@/views/client/app/Create.vue'),
                            },
                            {
                                title: '微信公众号详情',
                                meta: { title: '微信公众号详情' },
                                path: '/client/app/wechat',
                                name: 'client_app_wechat',
                                show: false,
                                component: () => import('@/views/client/app/WeChat.vue'),
                            },
                            {
                                title: '微信小程序详情',
                                meta: { title: '微信小程序详情' },
                                path: '/client/app/wxapp',
                                name: 'client_app_wxapp',
                                show: false,
                                component: () => import('@/views/client/app/Wxapp.vue'),
                            },
                            {
                                title: '安卓APP详情',
                                meta: { title: '安卓APP详情' },
                                path: '/client/app/android',
                                name: 'client_app_android',
                                show: false,
                                component: () => import('@/views/client/app/Android.vue'),
                            },
                            {
                                title: '苹果APP详情',
                                meta: { title: '苹果APP详情' },
                                path: '/client/app/ios',
                                name: 'client_app_ios',
                                show: false,
                                component: () => import('@/views/client/app/IOS.vue'),
                            },
                            {
                                title: 'H5详情',
                                meta: { title: 'H5详情' },
                                path: '/client/app/h5',
                                name: 'client_app_h5',
                                show: false,
                                component: () => import('@/views/client/app/H5.vue'),
                            },
                            {
                                title: 'PC网站详情',
                                meta: { title: 'PC网站详情' },
                                path: '/client/app/native',
                                name: 'client_app_native',
                                show: false,
                                component: () => import('@/views/client/app/Native.vue'),
                            },
                            //微信公众号相关相关
                            {
                                title: '微信公众号设置',
                                meta: { title: '微信公众号设置' },
                                path: '/client/wechat/config',
                                name: 'client_wechat_config',
                                show: false,
                                component: () => import('@/views/client/wechat/Config.vue'),
                            },
                            {
                                title: '自定义菜单',
                                meta: { title: '自定义菜单' },
                                path: '/client/wechat/menu',
                                name: 'client_wechat_menu',
                                show: false,
                                component: () => import('@/views/client/wechat/Menu.vue'),
                            },
                            {
                                title: '图文列表',
                                meta: { title: '图文列表' },
                                path: '/client/wechat/material',
                                name: 'client_wechat_material',
                                show: false,
                                component: () => import('@/views/client/wechat/Material.vue'),
                            },
                            {
                                title: '粉丝列表',
                                meta: { title: '粉丝列表' },
                                path: '/client/wechat/fans',
                                name: 'client_wechat_fans',
                                show: false,
                                component: () => import('@/views/client/wechat/Fans.vue'),
                            },
                            {
                                title: '回复设置',
                                meta: { title: '回复设置' },
                                path: '/client/wechat/replay',
                                name: 'client_wechat_replay',
                                show: false,
                                component: () => import('@/views/client/wechat/Replay.vue'),
                            },
                            {
                                title: '模板消息',
                                meta: { title: '模板消息' },
                                path: '/client/wechat/message',
                                name: 'client_wechat_message',
                                show: false,
                                component: () => import('@/views/client/wechat/Message.vue'),
                            },
                            {
                                title: '发布图文',
                                meta: { title: '发布图文' },
                                path: '/client/wechat/material_publish',
                                name: 'client_wechat_material_publish',
                                show: false,
                                component: () => import('@/views/client/wechat/MaterialPublish.vue'),
                            },
                            //微信小程序相关
                            {
                                title: '微信小程序设置',
                                meta: { title: '微信小程序设置' },
                                path: '/client/wxapp/config',
                                name: 'client_wxapp_config',
                                show: false,
                                component: () => import('@/views/client/wxapp/Config.vue'),
                            },
                            {
                                title: '小程序发布',
                                meta: { title: '小程序发布' },
                                path: '/client/wxapp/release',
                                name: 'client_wxapp_release',
                                show: false,
                                component: () => import('@/views/client/wxapp/Release.vue'),
                            },
                            {
                                title: '订阅消息',
                                meta: { title: '订阅消息' },
                                path: '/client/wxapp/message',
                                name: 'client_wxapp_message',
                                show: false,
                                component: () => import('@/views/client/wxapp/Message.vue'),
                            },
                            {
                                title: '网站设置',
                                meta: { title: '网站设置' },
                                path: '/client/native/config',
                                name: 'client_native_config',
                                show: false,
                                component: () => import('@/views/client/native/Config.vue'),
                            },
                            {
                                title: '网站SEO设置',
                                meta: { title: '网站SEO设置' },
                                path: '/client/native/seo',
                                name: 'client_native_seo',
                                show: false,
                                component: () => import('@/views/client/native/Seo.vue'),
                            },
                            {
                                title: '友情链接设置',
                                meta: { title: '友情链接设置' },
                                path: '/client/native/links',
                                name: 'client_native_links',
                                show: false,
                                component: () => import('@/views/client/native/Links.vue'),
                            },
                            {
                                title: '版权及ICP备案',
                                meta: { title: '版权及ICP备案' },
                                path: '/client/native/copyright',
                                name: 'client_native_copyright',
                                show: false,
                                component: () => import('@/views/client/native/Copyright.vue'),
                            },
                            {
                                title: '站点装修',
                                meta: { title: '站点装修' },
                                path: '/client/native/web',
                                name: 'client_native_web',
                                show: false,
                                component: () => import('@/views/client/native/Web.vue'),
                            },
                            {
                                title: '开发者文档',
                                meta: { title: '开发者文档' },
                                path: '/client/native/developer',
                                name: 'client_native_developer',
                                component: () => import('@/views/client/native/Developer.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                title: '管理',
                meta: { title: '管理', icon: 'icon-guanliyuan' },
                path: '/manage',
                name: 'manage',
                show: true,
                component: RouteView,
                redirect: '/manage/system',
                children: [
                    {
                        title: '系统管理',
                        meta: { title: '系统管理' },
                        path: '/manage/system',
                        name: 'manage_system',
                        show: true,
                        component: RouteView,
                        redirect: '/manage/system/user',
                        children: [
                            {
                                title: '用户管理',
                                meta: { title: '用户管理' },
                                path: '/manage/system/user',
                                name: 'manage_system_user',
                                show: true,
                                component: () => import('@/views/manage/system/User.vue'),
                            },
                            {
                                title: '角色管理',
                                meta: { title: '角色管理' },
                                path: '/manage/system/role',
                                name: 'manage_system_role',
                                show: true,
                                component: () => import('@/views/manage/system/Role.vue'),
                            },
                            {
                                title: '部门管理',
                                meta: { title: '部门管理' },
                                path: '/manage/system/dept',
                                name: 'manage_system_dept',
                                show: true,
                                component: () => import('@/views/manage/system/Dept.vue'),
                            },
                        ]
                    },
                    {
                        title: '菜单管理',
                        meta: { title: '菜单管理' },
                        path: '/manage/menu',
                        name: 'manage_menu',
                        show: true,
                        component: RouteView,
                        redirect: '/manage/menu/list',
                        children: [
                            {
                                title: '菜单列表',
                                meta: { title: '菜单列表' },
                                path: '/manage/menu/list',
                                name: 'manage_menu_list',
                                show: true,
                                component: () => import('@/views/manage/menu/List.vue'),
                            },
                            {
                                title: 'API权限',
                                meta: { title: 'API权限' },
                                path: '/manage/menu/api',
                                name: 'manage_menu_api',
                                show: false,
                                component: () => import('@/views/manage/menu/Api.vue'),
                            },
                            {
                                title: '数据字典',
                                meta: { title: '数据字典' },
                                path: '/manage/data/backup',
                                name: 'manage_data_backup',
                                show: false,
                                component: () => import('@/views/manage/data/Backup.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                title: '设置',
                meta: { title: '设置', icon: 'icon-shezhi' },
                path: '/setting',
                name: 'setting',
                show: true,
				component: RouteView,
                redirect: '/storage/files',
                children: [
                    {
                        title: '系统设置',
                        meta: { title: '系统设置' },
                        path: '/setting/system',
                        name: 'setting_system',
                        show: true,
						component: RouteView,
                        redirect: '/setting/system/site',
                        children: [
                            {
                                title: '关键词过滤',
                                meta: { title: '关键词过滤' },
                                path: '/setting/system/keyfilter',
                                name: 'setting_system_keyfilter',
                                show: true,
                                component: () => import('@/views/setting/system/Keyfilter.vue'),
                            },
                            {
                                title: '基本信息',
                                meta: { title: '基本信息' },
                                path: '/setting/system/site',
                                name: 'setting_system_site',
                                show: false,
                                component: () => import('@/views/setting/system/Site.vue'),
                            },
                            {
                                title: '版权信息',
                                meta: { title: '版权信息' },
                                path: '/setting/system/copyright',
                                name: 'setting_system_copyright',
                                show: false,
                                component: () => import('@/views/setting/system/Copyright.vue'),
                            },
                        ]
                    },
                    {
                        title: '支付设置',
                        meta: { title: '支付设置' },
                        path: '/payment/setting',
                        name: 'payment_setting',
                        show: false,
						component: RouteView,
                        redirect: '/payment/setting/list',
                        children: [
                            {
                                title: '支付方式',
                                meta: { title: '支付方式' },
                                path: '/payment/setting/list',
                                name: 'payment_setting_list',
                                show: true,
                                component: () => import('@/views/payment/setting/List.vue'),
                            },
                            {
                                title: '微信支付',
                                meta: { title: '微信支付' },
                                path: '/payment/setting/wechat',
                                name: 'payment_setting_wechat',
                                show: true,
                                component: () => import('@/views/payment/setting/Wechat.vue'),
                            },
                            {
                                title: '支付宝支付',
                                meta: { title: '支付宝支付' },
                                path: '/payment/setting/alipay',
                                name: 'payment_setting_alipay',
                                show: true,
                                component: () => import('@/views/payment/setting/Alipay.vue'),
                            },
                        ]
                    },
                    {
                        title: '短信设置',
                        meta: { title: '短信设置' },
                        path: '/sms/setting',
                        name: 'sms_setting',
                        show: false,
						component: RouteView,
                        redirect: '/sms/list',
                        children: [
                            {
                                title: '配置信息',
                                meta: { title: '配置信息' },
                                path: '/sms/list',
                                name: 'sms_list',
                                show: true,
                                component: () => import('@/views/sms/List.vue'),
                            },
                            {
                                title: '模板管理',
                                meta: { title: '模板管理' },
                                path: '/sms/template',
                                name: 'sms_template',
                                show: true,
                                component: () => import('@/views/sms/Template.vue'),
                            },
                        ]
                    },
                    {
                        title: '文件存储',
                        meta: { title: '文件存储' },
                        path: '/storage/files',
                        name: 'storage_files',
                        show: true,
						component: RouteView,
                        redirect: '/storage/files/file',
                        children: [
                            {
                                title: '文件列表',
                                meta: { title: '文件列表' },
                                path: '/storage/files/file',
                                name: 'storage_files_file',
                                show: true,
                                component: () => import('@/views/storage/files/File.vue'),
                            },
                            {
                                title: '文件分组',
                                meta: { title: '文件分组' },
                                path: '/storage/files/group',
                                name: 'storage_files_group',
                                show: true,
                                component: () => import('@/views/storage/files/Group.vue'),
                            },
                            {
                                title: '存储设置',
                                meta: { title: '存储设置' },
                                path: '/storage/setting/upload',
                                name: 'storage_setting_upload',
                                show: true,
                                component: () => import('@/views/storage/setting/Upload.vue'),
                            },
                        ]
                    }
                ]
            },
            {
                title: '开发',
                meta: { title: '开发', icon: 'icon-shezhi' },
                path: '/develop',
                name: 'develop',
                show: false,
				component: RouteView,
                redirect: '/develop/manage',
                children: [
                    {
                        title: '页面设计',
                        meta: { title: '页面设计' },
                        path: '/develop/manage',
                        name: 'develop_manage',
                        show: true,
                        component: () => import('@/views/develop/Manage.vue'),
                    },
                    {
                        title: '测试demo',
                        meta: { title: '测试demo' },
                        path: '/develop/test',
                        name: 'develop_test',
                        show: true,
                        component: () => import('@/views/develop/Test.vue'),
                    },
                    {
                        title: '移动页面',
                        meta: { title: '移动页面' },
                        path: '/develop/pages',
                        name: 'develop_pages',
                        show: true,
						component: RouteView,
                        redirect: '/develop/pages/list',
                        children: [
                            {
                                title: '页面设计',
                                meta: { title: '页面设计' },
                                path: '/develop/pages/list',
                                name: 'develop_pages_list',
                                show: true,
                                component: () => import('@/views/develop/Pages.vue'),
                            },
                            {
                                title: '设计页面',
                                meta: { title: '设计页面' },
                                path: '/develop/design',
                                name: 'develop_design',
                                show: true,
                                component: () => import('@/views/develop/Design.vue'),
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('@/views/passport/login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: RouteView,
        redirect: '/404',
        children: [
            {
                path: '/403',
                name: '403',
                meta: { title: '404 page' },
                component: () => import('@/views/error/403.vue'),
            },
            {
                path: '/404',
                name: '404',
                meta: { title: '404 page' },
                component: () => import('@/views/error/404.vue'),
            },
            {
                path: '/500',
                name: '500',
                meta: { title: '404 page' },
                component: () => import('@/views/error/500.vue'),
            },
            {
                path: '/blank',
                name: 'blank',
                meta: { title: '空白页' },
                component: () => import('@/views/error/blank.vue'),
            },
        ]
    },
    {
        path: '/install',
        name: 'install',
        meta: { title: '安装程序' },
        component: () => import('@/views/install/Index.vue'),
    },
]
